import { useState } from "react";
import { useData } from "@microsoft/teamsfx-react";
import Axios from "axios";
import config from "./lib/config";
import * as axios from "axios";
import OnCall from "./Dialpad/OnCall";
import Dialpad from "./Dialpad";
import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import alok from "../assets/img/alok.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContactCard from "./Grid/ContactCard";

/* Contacus API */

const functionName = "getUserPeople";

async function callFunction(teamsUserCredential: TeamsUserCredential) {
  try {
    const apiBaseUrl = config.apiEndpoint + "/api/";
    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
      apiBaseUrl,
      new BearerTokenAuthProvider(
        async () => (await teamsUserCredential.getToken(""))!.token
      )
    );
    const response = await apiClient.get(functionName);
    return response.data;
  } catch (err: unknown) {
    if (axios.default.isAxiosError(err)) {
      let funcErrorMsg = "";

      if (err?.response?.status === 404) {
        funcErrorMsg = `There may be a problem with the deployment of Azure Functions App, please deploy Azure Functions (Run command palette "Teams: Deploy") first before running this App`;
      } else if (err.message === "Network Error") {
        funcErrorMsg =
          "Cannot call Azure Functions due to network error, please check your network connection status and ";
        if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
          funcErrorMsg += `make sure to start Azure Functions locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          funcErrorMsg += `make sure to provision and deploy Azure Functions (Run command palette "Teams: Provision" and "Teams: Deploy") first before running this App`;
        }
      } else {
        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ": " + err.response.data.error;
        }
      }

      throw new Error(funcErrorMsg);
    }
    throw err;
  }
}
/* Ends*/

const Recent = (props) => {
  const [contactlist, setContactlist] = useState<any[]>([]);
  const [callStatus, setCallStatus] = useState({});
  const [dialContactName, setDialContactName] = useState("");
  const [dialContactNumber, setDialContactNumber] = useState("");
  const [apiData, setapiData] = useState({
    apiurl: process.env.REACT_APP_BACKEND_API_URL,
    apitoken: process.env.REACT_APP_BACKEND_API_TOKEN,
  });
  const [needConsent, setNeedConsent] = useState(false);
  console.log("recent");
  console.log(props.teamsUserCredential);

  const { loading, data, error, reload } = useData(async () => {
    if (!props.teamsUserCredential) {
      console.log("TeamsFx SDK is not initialized.");
    } else {
      const usercontacts = await callFunction(props.teamsUserCredential);
      setContactlist(usercontacts.graphClientMessage.value);
      return usercontacts;
    }
  });

  const handleDialClick = async (contactname, phone) => {
    setCallStatus("Dial Now");
    setDialContactName(contactname);
    setDialContactNumber(phone);
    props.onHandleRing("On Call");
    const params = JSON.stringify({
      action: "dialeraction",
      method: "dial",
      emailaddress: props.accountemail,
      contact: phone,
    });
    const headerconfig = {
      headers: {
        "Content-Type": "application/json",
        authorization: apiData.apitoken!,
      },
    };
    const result = await Axios.post(apiData.apiurl!, params, headerconfig);
  };
  const handleHungUpClick = () => {
    setCallStatus("Hung Up");
    props.onHandleRing("Hung Up");
  };

  console.log(contactlist);

  return (
    <>
      <div className="recent-contacts">
        <div className="container">
          <div className="row justify-content-left">
            <div className="col-12 d-lg-none">
              <p>
                <button
                  className="btn btn-success btn-viewrecent"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#recentContacts"
                  aria-expanded="false"
                  aria-controls="recentContacts"
                >
                  View Recent Contacts
                </button>
              </p>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="row align-items-center pt-2 owl-carousel dd">
                <div
                  className="collapse collapse-horizontal d-none d-lg-block"
                  id="recentContacts"
                >
                  <div className="sideBar" style={{ width: 300 }}>
                    <div className="slider">
                      <div className="news-list">
                        {contactlist.length > 0 && (
                          <>
                            {contactlist.map((contact) => (
                              <>
                                {contact.phones.length > 0 ? (
                                  <>
                                    {contact.phones.map((phone) => (
                                      <>
                                        {phone.number.trim().length > 0 ? (
                                          <>
                                            <div className="col item">
                                              <ContactCard
                                                imagePath={alok}
                                                contactEmail={
                                                  contact.userPrincipalName
                                                }
                                                teamsUserCredential={
                                                  props.teamsUserCredential
                                                }
                                                contactName={
                                                  contact.displayName
                                                }
                                                contactNumber={phone.number}
                                                accountemail={
                                                  props.accountemail
                                                }
                                                onhandleDialClick={
                                                  handleDialClick
                                                }
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  {callStatus === "Dial Now" && (
                    <OnCall
                      name={dialContactName}
                      number={dialContactNumber}
                      status={callStatus}
                      onhandleHungUpClick={handleHungUpClick}
                      accountemail={props.accountemail}
                    />
                  )}
                </>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-12 col-12">
              <Dialpad userEmail={props.accountemail} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Recent;
