import React, { useState } from "react";

const Navigation = (props) => {
  const [action, setAction] = useState("Home");

  const handleChange = (action) => {
    props.onAction(action);
    setAction(action);
  };

  const name = props.userName;

  const firstChar = name.charAt(0);
  const lastChar = name.charAt(name.indexOf(" ") + 1);

  return (
    <>
      <div className="header">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between py-2">
            <div className="logo_menu_section d-flex align-items-center gap-3">
              <div className="headphone_icon">
                <span className="material-symbols-outlined fill">
                  headphones
                </span>
              </div>
              <div className="nav_section mobile_nav_hidden">
                <ul className="nav">
                  <li className="nav-item">
                    <a
                      className={
                        action == "Home" ? "nav-link active" : "nav-link"
                      }
                      aria-current="page"
                      href="#"
                      onClick={() => {
                        handleChange("Home");
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        action == "Contacts" ? "nav-link active" : "nav-link"
                      }
                      href="#"
                      onClick={() => {
                        handleChange("Contacts");
                      }}
                    >
                      Contacts
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://dialer.emetrotel.net">
                      Sign Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="ms-auto profile_section profile_section_hidden d-flex align-items-center">
              <span className="profile_name me-2">
                <span>
                  {firstChar}
                  {lastChar}
                </span>
              </span>
              <span>
                <span className="text_light_green">
                  {props.userName !== "Null" ? props.userName : ""}!
                </span>
              </span>
            </div>
            <button
              className="hamburger_menu d-md-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="material-symbols-outlined">menu</span>
            </button>
          </div>
        </div>
        <div className="collapse d-md-none p-3" id="navbarSupportedContent">
          <div className="nav_section">
            <ul className="nav flex-column">
              <li className="nav-item">
                <a
                  className={action == "Home" ? "nav-link active" : "nav-link"}
                  aria-current="page"
                  href="#"
                  onClick={() => {
                    handleChange("Home");
                  }}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    action == "Contacts" ? "nav-link active" : "nav-link"
                  }
                  href="#"
                  onClick={() => {
                    handleChange("Contacts");
                  }}
                >
                  Contacts
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://dialer.emetrotel.net">
                  Sign Out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
