import { useState } from "react";
import OnCall from "../Dialpad/OnCall";

const CallButton = (props) => {
  const [dialStatus, setDialStatus] = useState("");

  const handleClick = async (contactname, phone) => {
    props.onhandleDialClick(contactname, phone);
  };

  return (
    <>
      <div
        className="call-icon"
        onClick={() => handleClick(props.contactName, props.phone)}
      >
        <span className="material-symbols-outlined fill">call</span>
      </div>
    </>
  );
};

export default CallButton;
