import { useState } from "react";
import { useData } from "@microsoft/teamsfx-react";
import Axios from "axios";
import config from "./lib/config";
import * as axios from "axios";
import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import alok from "../assets/img/alok.png";
import ContactCard from "./Grid/ContactCard";

import OnCall from "./Dialpad/OnCall";

/* Contacus API */

const functionName = "getUserContacts";

async function callFunction(teamsUserCredential: TeamsUserCredential) {
  try {
    const apiBaseUrl = config.apiEndpoint + "/api/";
    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
      apiBaseUrl,
      new BearerTokenAuthProvider(
        async () => (await teamsUserCredential.getToken(""))!.token
      )
    );
    const response = await apiClient.get(functionName);
    return response.data;
  } catch (err: unknown) {
    if (axios.default.isAxiosError(err)) {
      let funcErrorMsg = "";

      if (err?.response?.status === 404) {
        funcErrorMsg = `There may be a problem with the deployment of Azure Functions App, please deploy Azure Functions (Run command palette "Teams: Deploy") first before running this App`;
      } else if (err.message === "Network Error") {
        funcErrorMsg =
          "Cannot call Azure Functions due to network error, please check your network connection status and ";
        if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
          funcErrorMsg += `make sure to start Azure Functions locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          funcErrorMsg += `make sure to provision and deploy Azure Functions (Run command palette "Teams: Provision" and "Teams: Deploy") first before running this App`;
        }
      } else {
        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ": " + err.response.data.error;
        }
      }

      throw new Error(funcErrorMsg);
    }
    throw err;
  }
}
/* Ends*/

const Contacts = (props) => {
  const [contactlist, setContactlist] = useState<any[]>([]);
  const [callStatus, setCallStatus] = useState({});
  const [dialContactName, setDialContactName] = useState("");
  const [dialContactNumber, setDialContactNumber] = useState("");

  const [apiData, setapiData] = useState({
    apiurl: process.env.REACT_APP_BACKEND_API_URL,
    apitoken: process.env.REACT_APP_BACKEND_API_TOKEN,
  });

  console.log("contact");
  console.log(props.teamsUserCredential);

  const handleDialClick = async (contactname, phone) => {
    setCallStatus("Dial Now");
    console.log(callStatus);
    setDialContactName(contactname);
    setDialContactNumber(phone);
    const params = JSON.stringify({
      action: "dialeraction",
      method: "dial",
      emailaddress: props.accountemail,
      contact: phone,
    });
    const headerconfig = {
      headers: {
        "Content-Type": "application/json",
        authorization: apiData.apitoken!,
      },
    };
    const result = await Axios.post(apiData.apiurl!, params, headerconfig);
  };
  const handleHungUpClick = () => {
    setCallStatus("Hung Up");
    console.log(callStatus);
  };

  const { loading, data, error, reload } = useData(async () => {
    if (!props.teamsUserCredential) {
      console.log("TeamsFx SDK is not initialized.");
    } else {
      const usercontacts = await callFunction(props.teamsUserCredential);
      setContactlist(usercontacts.graphClientMessage.value);
      return usercontacts;
    }
  });

  const fetchemail = (jsonobject) => {
    return jsonobject[0].address;
  };
  console.log(contactlist);
  return (
    <>
      <div className="recent-contacts">
        <div className="container">
          <div className="contact_heading">
            <h5>Contacts</h5>
          </div>

          <div className="contact_list">
            <div className="row row-cols-1 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 pt-2">
              {contactlist.map((contact) => (
                <>
                  {contact.mobilePhone ||
                  contact.businessPhones.length > 0 ||
                  contact.homePhones.length > 0 ? (
                    <>
                      {contact.mobilePhone ? (
                        <>
                          <div className="col">
                            <ContactCard
                              imagePath={alok}
                              contactEmail={fetchemail(contact.emailAddresses)}
                              teamsUserCredential={props.teamsUserCredential}
                              contactName={contact.displayName}
                              contactNumber={contact.mobilePhone}
                              accountemail={props.accountemail}
                              onhandleDialClick={handleDialClick}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {contact.businessPhones.length > 0 ? (
                        <>
                          {contact.businessPhones.map((bphone) => (
                            <>
                              {bphone.trim().length > 0 ? (
                                <>
                                  <div className="col">
                                    <ContactCard
                                      imagePath={alok}
                                      contactEmail={fetchemail(
                                        contact.emailAddresses
                                      )}
                                      teamsUserCredential={
                                        props.teamsUserCredential
                                      }
                                      contactName={contact.displayName}
                                      contactNumber={bphone}
                                      accountemail={props.accountemail}
                                      onhandleDialClick={handleDialClick}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {contact.homePhones.length > 0 ? (
                        <>
                          {contact.homePhones.map((hphone) => (
                            <>
                              {hphone.trim().length > 0 ? (
                                <>
                                  <div className="col">
                                    <ContactCard
                                      imagePath={alok}
                                      contactemail={fetchemail(
                                        contact.emailAddresses
                                      )}
                                      teamsUserCredential={
                                        props.teamsUserCredential
                                      }
                                      contactName={contact.displayName}
                                      contactNumber={hphone}
                                      accountemail={props.accountemail}
                                      onhandleDialClick={handleDialClick}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <>
        {callStatus == "Dial Now" && (
          <OnCall
            name={dialContactName}
            number={dialContactNumber}
            status={callStatus}
            onhandleHungUpClick={handleHungUpClick}
            accountemail={props.accountemail}
          />
        )}
      </>
    </>
  );
};
export default Contacts;
