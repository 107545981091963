import Recent from "./Recent";

const Home = (props) => {
  const HandleRing = (option) => {
    props.onHandleRing(option);
  };
  return (
    <>
      <Recent
        teamsUserCredential={props.teamsUserCredential}
        accountemail={props.userEmail}
        onHandleRing={HandleRing}
      />
    </>
  );
};

export default Home;
