import { useContext, useState } from "react";
import { useData } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";
import Contacts from "./Contacts";
import Configure from "./Configure";
import Home from "./Home";
import Navigation from "./Navigation";
import Axios from "axios";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
const BACKEND_API_TOKEN = process.env.REACT_APP_BACKEND_API_TOKEN;

const validateaccount = async (
  emailaddress,
  backendapiurl,
  backendapitoken
) => {
  const params = JSON.stringify({
    action: "validate",
    emailaddress: emailaddress,
  });
  const headerconfig = {
    headers: {
      "Content-Type": "application/json",
      authorization: backendapitoken,
    },
  };

  const response = await Axios.post(backendapiurl, params, headerconfig);
  return response;
};

const Main = () => {
  const { teamsUserCredential } = useContext(TeamsFxContext);

  const [needConsent, setNeedConsent] = useState(true);

  const { loading, data, error, reload } = useData(async () => {
    if (!teamsUserCredential) {
      console.log("TeamsFx SDK is not initialized.");
    } else {
      if (needConsent) {
        await teamsUserCredential!.login([
          "User.Read",
          "Contacts.Read",
          "People.Read",
        ]);
        setNeedConsent(true);
      }
      try {
        const userInfo = await teamsUserCredential.getUserInfo();
        return userInfo;
      } catch (error: any) {
        if (error.message.includes("The application may not be authorized.")) {
          setNeedConsent(true);
        }
      }
    }
  });

  var userName = "Null";
  var userEmail = "Null";
  console.log(data);
  const [dialersubscribed, setDialersubscribed] = useState("");
  if (data !== undefined) {
    userName = data!.displayName;
    if (data!.preferredUserName != "Null") {
      userEmail = data!.preferredUserName;
      const subscribed = validateaccount(
        data!.preferredUserName,
        BACKEND_API_URL,
        BACKEND_API_TOKEN
      );
      subscribed.then(function (result) {
        const res = JSON.parse(result.data.message);
        if (res.status == "Success") {
          setDialersubscribed("Valid");
        } else {
          setDialersubscribed("InValid");
        }
      });
    }
  }
  //console.log(data!.contacts.graphClientMessage.value);

  const [action, setAction] = useState("Home");
  const [timeOut, setTimeOut] = useState(false);
  const [ringaction, setRingAction] = useState("Hung Up");

  const HandleSubscription = (status) => {
    setDialersubscribed(status);
    if (timeOut == false) {
      setTimeOut(true);
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setTimeOut(false);
      }, 3000);
    }
  };

  const HandleAction = (nav) => {
    setAction(nav);
  };
  const HandleRing = (option) => {
    setRingAction(option);
  };
  return (
    <>
      {userName === "Null" ? (
        <div className="content_bg">
          <div className="w-100 h-100 text-center pt-5 pb-5">
            <button className="mt-20 btn submit-btn" onClick={reload}>
              Sign In With Microsoft
            </button>
          </div>
        </div>
      ) : (
        <>
          <div
            className={
              dialersubscribed == "Valid" && action === "Home"
                ? "dialer_bg dialer_bg_new"
                : "contact_bg"
            }
          >
            <Navigation
              userName={userName}
              onAction={HandleAction}
              dialersubscribed={dialersubscribed}
              teamsUserCredential={teamsUserCredential}
              emailaddress={userEmail}
            />
            {dialersubscribed === "InValid" ? (
              <>
                <Configure
                  onSubscription={HandleSubscription}
                  userEmail={userEmail}
                />
              </>
            ) : (
              <></>
            )}
            {dialersubscribed === "Valid" ? (
              <>
                {timeOut && (
                  <div className="row alert alert-success rounded-0">
                    Successfull Activated Infinity Dialer For Teams
                  </div>
                )}
                {action === "Home" ? (
                  <Home
                    userEmail={userEmail}
                    teamsUserCredential={teamsUserCredential}
                    onHandleRing={HandleRing}
                  />
                ) : (
                  <></>
                )}
                {action === "Contacts" ? (
                  <>
                    <Contacts
                      teamsUserCredential={teamsUserCredential}
                      accountemail={userEmail}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          {dialersubscribed === "Valid" &&
          action === "Home" &&
          ringaction === "Hung Up" ? (
            <></>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default Main;
