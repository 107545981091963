import React, { useCallback, useState } from "react";
import Axios from "axios";
import logo from "../../assets/img/logo_dark.png";
import OnCall from "../Dialpad/OnCall";

const DialPad = (props) => {
  const { onCallClick, onHangUpClick, userEmail } = props;
  const [apiData, setapiData] = useState({
    apiurl: process.env.REACT_APP_BACKEND_API_URL,
    apitoken: process.env.REACT_APP_BACKEND_API_TOKEN,
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialingStatus, setDialingStatus] = useState("Hung Up");

  const handleDigitClick = useCallback(
    (value) => (event) => {
      setPhoneNumber((phoneNumber) => phoneNumber.concat(value));
    },
    []
  );

  const handleClick = async () => {
    const params = JSON.stringify({
      action: "dialeraction",
      method: "dial",
      emailaddress: userEmail,
      contact: phoneNumber,
    });
    const headerconfig = {
      headers: {
        "Content-Type": "application/json",
        authorization: apiData.apitoken!,
      },
    };
    //const result = await Axios.post(apiData.apiurl!, params, headerconfig);
    await Axios.post(apiData.apiurl!, params, headerconfig).then(function (
      result
    ) {
      const res = JSON.parse(result.data.message);
      if (res.status == "Success") {
        setDialingStatus("Dial Now");
        console.log(dialingStatus);
      } else {
        setDialingStatus("Hung Up");
        console.log(dialingStatus);
      }
    });
    //console.log(result); // '{"name":"John Doe"}'
    //onCallClick(phoneNumber)
  };

  const handleHangUpClick = () => {
    setDialingStatus("Hung Up");
    setPhoneNumber("");
  };

  const handleBackspaceClick = () => {
    setPhoneNumber((phoneNumber) => phoneNumber.slice(0, -1));
  };

  return (
    <>
      {dialingStatus === "Hung Up" && (
        <div className="dialer dialer_new text-center">
          <div className="logo">
            <img src={logo} alt="logo" className="logo_img mb-3" />
          </div>
          <p>{phoneNumber}</p>
          <div className="number-pad">
            <button onClick={handleDigitClick("1")}>1</button>
            <button onClick={handleDigitClick("2")}>
              2 <span>ABC</span>
            </button>
            <button onClick={handleDigitClick("3")}>
              3 <span>DEF</span>
            </button>
            <button onClick={handleDigitClick("4")}>
              4 <span>GHI</span>
            </button>
            <button onClick={handleDigitClick("5")}>
              5 <span>JKL</span>
            </button>
            <button onClick={handleDigitClick("6")}>
              6 <span>MNO</span>
            </button>
            <button onClick={handleDigitClick("7")}>
              7 <span>PQRS</span>
            </button>
            <button onClick={handleDigitClick("8")}>
              8 <span>TUV</span>
            </button>
            <button onClick={handleDigitClick("9")}>
              9 <span>WXYZ</span>
            </button>
            <button>*</button>
            <button onClick={handleDigitClick("0")}>
              0 <span>+</span>
            </button>
            <button onClick={handleDigitClick("#")}>#</button>
          </div>
          <div className="d-flex justify-content-center gap-3 mt-3">
            <button className="call-button me-2" onClick={handleClick}>
              <span className="material-symbols-outlined fill"> call </span>
            </button>
            <button
              className="call-button back_space_btn"
              onClick={handleBackspaceClick}
            >
              <span className="material-symbols-outlined"> backspace </span>
            </button>
          </div>
        </div>
      )}
      {dialingStatus == "Dial Now" && (
        <>
          <div className="dialer_oncall">
            <OnCall
              number={phoneNumber}
              status={dialingStatus}
              onhandleHungUpClick={handleHangUpClick}
              accountemail={userEmail}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DialPad;
