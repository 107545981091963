import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "./lib/config";
import Main from "./Main";
import { TeamsFxContext } from "./Context";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });
  return (
    <TeamsFxContext.Provider value={{ teamsUserCredential }}>
      <>
        <Main />
      </>
    </TeamsFxContext.Provider>
  );
}
